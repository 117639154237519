import React from 'react';
import './ABLocations.scss';
import { Container, Row, Col } from "react-bootstrap";
import ABMapContainer from "./ABMapContainer";


const ABLocations = () => {
    const stores = [
        { key:0, title: '397 Arguello', open: true, phone: '415-750-9460', openEveryday:true, hours:{weekdays:'7a - 2:30p', weekends:'8a - 3:30p'}, coords: { lat: 37.7834259, lng: -122.4593062 } }, 
        { key:1, title: '87 McAllister', open: false, phone: null, openEveryday: false, hours:{weekdays:null, weekends:null}, coords: { lat: 37.7808373, lng: -122.4133492 } }
    ]
    return (
        <div className="locations-container content-item">
            <div className="store-card">
                <Container className="store-details">
                    <Row><Col><h2>397 Arguello</h2></Col></Row>
                    <Row><Col>Phone</Col><Col>415-750-9460</Col></Row>
                    <Row><Col>Mon - Fri</Col><Col>8a - 3p</Col></Row>
                    <Row><Col>Sat &amp; Sun</Col><Col>8a - 3:30p</Col></Row>
                    <Row><Col>&nbsp;</Col><Col> </Col></Row>

                </Container>
                <ABMapContainer coords={stores[0].coords} />
            </div>
            <div className="store-card">
                <Container className="store-details">
                    <Row><Col><h2>87 McAllister</h2></Col></Row>
                    <Row><Col>Phone</Col><Col>415-926-5155</Col></Row>
                    <Row><Col>Mon - Fri</Col><Col>8a - 3p</Col></Row>
                    <Row><Col>Sat &amp; Sun</Col><Col>8a - 3p</Col></Row>
                    <Row><Col>&nbsp;</Col><Col> </Col></Row>

                </Container>
                <ABMapContainer coords={stores[1].coords} />
            </div>
        </div>
    )
}

export default ABLocations